<template>
  <div>
    <el-dialog
      title="Tips"
      :visible.sync="isOpen"
      width="540px"
      :before-close="handleClose"
    >
      <template slot="title">
        <div class="fs-20 font-bold uppercase">
          {{ title }}
        </div>
      </template>
      <div class="text-black" v-loading="isLoading">
        <div v-if="type === 1" class="flex gap-1 items-center">
          <div class="fs-16">{{ $t("Xác nhận xoá") }}</div>
          <div class="font-bold fs-16">{{ vitalSignHeader.name || "" }}</div>
        </div>
        <div v-if="type === 2" class="flex flex-col gap-2 justify-center">
          <div class="p-2" v-for="(item, index) in vitalsigns" :key="index">
            <el-checkbox
              class="cs-checkbox text-black"
              v-model="item.checked"
              >{{ getVitalSignName(item) }}</el-checkbox
            >
            <el-divider class="my-1"></el-divider>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col md:flex-row items-center gap-2 justify-end mt-4"
      >
        <el-button class="flex-1" plain type="primary" @click="handleClose">{{
          $t("Đóng")
        }}</el-button>
        <el-button
          class="flex-1"
          style="background-color: #b42318; border: none"
          type="primary"
          @click="handleSubmit"
          >{{ $t("Xác nhận xoá") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ModalDeleteVitalSign',
  data () {
    return {
      isOpen: false,
      title: 'XÁC NHẬN XÓA',
      vitalSignHeader: {},
      type: 1, // vitalsignHeader,
      vitalsigns: [],
      isLoading: false
    }
  },
  methods: {
    handleClose () {
      this.isOpen = false
      this.vitalsigns = []
      this.vitalSignHeader = {}
      this.type = 1
    },
    handleOpen (type, vitalSignHeader) {
      this.isOpen = true
      this.type = type
      this.vitalSignHeader = vitalSignHeader
      if (type === 2) {
        this.title = 'CHỌN CẬP NHẬT CHỈ SỐ CẦN XÓA'
        this.getVitalsignHeaderDetail(vitalSignHeader?.id)
      }
    },
    async handleSubmit () {
      try {
        this.isLoading = true

        if (this.type === 1) {
          const response = await this.$rf
            .getRequest('DoctorRequest')
            .deleteVitalSignHeader(this.vitalSignHeader?.id)
          if (response.status === 200) {
            this.$message({
              type: 'success',
              message: this.$t('Xoá phiếu thành công')
            })
            this.$emit('onSuccess')
            this.handleClose()
          }
        } else {
          const checkedVitalSigns = this.vitalsigns
            .filter((item) => item.checked)
            ?.map((item) => item?.id)

          const response = await this.$rf
            .getRequest('DoctorRequest')
            .deleteVitalSigns({ vital_sign_ids: checkedVitalSigns })

          if (response.status === 200) {
            this.$message({
              type: 'success',
              message: this.$t('Xoá chỉ số thành công')
            })
            this.$emit('onSuccess')
            this.handleClose()
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getVitalsignHeaderDetail (id) {
      if (!id) return

      try {
        this.isLoading = true
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .showVitalsignHeader(id)

        const responseData = response.data?.vitalsigns || []

        this.vitalsigns = responseData?.map((item) => ({
          ...item,
          checked: false
        }))
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    getVitalSignName (item) {
      const time = item?.record_time
        ? window.moment(item?.record_time).format('HH:mm DD/MM/YYYY')
        : ''
      return `Cập nhật chỉ số ${time || ''}`
    }
  }
}
</script>

<style lang="scss" scoped>
.cs-checkbox {
  .el-checkbox__label {
    font-size: 16px !important;
  }
}
</style>